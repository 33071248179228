@font-face {
  font-family: "prototype";
  src: url("./Prototype.woff") format('woff');
}

/* @font-face {
  font-family: "myfont";
  src: url("fonts/awesome-font-bold.woff") format('woff');
  font-weight: bold;
}

@font-face {
  font-family: "myfont";
  src: url("fonts/awesome-font-boldoblique.woff") format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "myfont";
  src: url("fonts/awesome-font-oblique.woff") format('woff');
  font-style: italic;
} */


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* font-family: 'prototype'; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
